import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IRBAC_SERVICE, IRbacService } from '../rbac-service.interface';

@Injectable()
export class CanGuard {
  constructor(private router: Router, @Inject(IRBAC_SERVICE) private rbacService: IRbacService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const data = route.data;
    if (data['middleware']) {
      /**
       * Single permission
       */
      if (data['middleware'].permission) {
        const can = this.rbacService.can(data['middleware'].permission);
        if (can) {
          return of(true);
        } else {
          if (data['middleware'].condition === 'redirect') {
            this.router.navigate([data['middleware'].resolve]);
            return of(false);
          } else {
            this.router.navigate(['app/dashboard']);
            return of(false);
          }
        }
      }
      /**
       * Action permission
       */
      if (data['middleware'].actionPermission) {
        const can = this.rbacService.canAction(data['middleware'].actionPermission);
        if (can) {
          return of(true);
        } else {
          if (data['middleware'].condition === 'redirect') {
            this.router.navigate([data['middleware'].resolve]);
            return of(false);
          } else {
            this.router.navigate(['app/dashboard']);
            return of(false);
          }
        }
      }
      /**
       *  Every
       */
      if (data['middleware'].everyPermission) {
        const can = this.rbacService.canEvery(data['middleware'].everyPermission);
        if (can) {
          return of(true);
        } else {
          if (data['middleware'].condition === 'redirect') {
            this.router.navigate([data['middleware'].resolve]);
            return of(false);
          } else {
            this.router.navigate(['app/dashboard']);
            return of(false);
          }
        }
      }
    }
    return of(false);
  }
}
